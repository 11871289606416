import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "./axiosInstance";
import { Player } from "@lottiefiles/react-lottie-player";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCode} from "@fortawesome/free-solid-svg-icons";
import EmbedDialog from "./Dialogs/Embed/EmbedDialog";
import Dialog from "./Dialogs/Dialog";

const SingleTemplateView = () => {
    const { templateId } = useParams();
    const [template, setTemplate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [isEmbedDialogOpen, setEmbedDialogOpen] = useState(false);

    const openEmbedDialog = (templateId) => {
        setEmbedDialogOpen(true);
    };

    const closeEmbedDialog = () => {
        setEmbedDialogOpen(false);
    };

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const res = await api.get(`/templates/${templateId}`, {
                    withCredentials: true,
                });
                setTemplate(res.data);
            } catch (err) {
                console.error("Error fetching template:", err);
                setError("Error loading template details!");
            } finally {
                setLoading(false);
            }
        };

        if (templateId) {
            fetchTemplate();
        }
    }, [templateId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="single-template-view container">
            {template ? (
                <>
                    <Dialog isOpen={isEmbedDialogOpen} onClose={closeEmbedDialog} type="embed" templateId={templateId}>
                        {isEmbedDialogOpen && (
                            <EmbedDialog templateId={templateId} onClose={closeEmbedDialog} />
                        )}
                    </Dialog>
                    <h2>{template.name}</h2>
                    <Player
                        src={template.data.templateJson}
                        style={{ height: "auto", width: "100%" }}
                        autoplay
                        loop
                    />
                    <p>{template.description}</p>
                    <p>
                        <strong>Created:</strong>{" "}
                        {new Date(template.createdAt).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                        })}
                    </p>
                    <p>
                        <strong>Last Edited:</strong>{" "}
                        {new Date(template.updatedAt).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                        })}
                    </p>
                    <button
                        onClick={() => {
                            const ferrymanUrl = `https://demo.ferryman.streamshapers.com?templateId=${template._id}`;
                            window.open(ferrymanUrl, "_blank");
                        }}
                    >
                        Open in Ferryman to Edit
                    </button>
                    <button onClick={() => openEmbedDialog(template._id)}>
                        <FontAwesomeIcon icon={faCode}/> Generate Embed
                    </button>
                </>
            ) : (
                <p>No template found.</p>
            )}
        </div>
    );
};

export default SingleTemplateView;
